<template>
  <div class='template'>    
      <article class="images">
          <transition name="fade" appear>
            <div :style="{backgroundImage:`url(${page?.background})`}"/>
          </transition>
      </article>      
      <transition name="fade" appear>
        <div class="left-bar"/> 
      </transition>
      <article class='text-content'>
            <transition name="slide" appear>
                <div>
                    <h1>{{page.title}}</h1>
                    <Editor @click="activateEditor" plugins="lists" api-key="2uyqbbnykquw7zpsd3ht43lzwe4s4dyu3l8x6p7vgvx8v4ug" :init="{menubar:false}" toolbar='[ undo redo | bold italic underline | fontsize forecolor | bullist numlist link ]' v-model="copy" class="copy" v-if="copy || $store.state.devMode" inline :disabled="!editorFocused" @change="updateContent"/>                    
                    <AttachmentUI :page="page" :gallery="gallery" @showGallery="galleryVisible = true" @showVideo="showVideo"/>
                </div>
            </transition>   
            <div class="linear-holder">
                <LinearButton :to="page?.previous" previous/>
                <LinearButton :to="page?.next"/>
            </div>
       </article>         
      <transition name="fade">
        <Gallery :images="gallery" @close="galleryVisible = false" v-show="galleryVisible"/>
      </transition>
      <transition name="fade">
        <Gallery videoMode :images="[]" :videoId="videoId" @close="videoVisible = false" v-if="videoVisible" :show="videoVisible"/>
      </transition>
  </div>  
</template>

<script>
import Page from '@/mixins/Page';
import AttachmentUI from '@/components/AttachmentUI';
import LinearButton from '@/components/LinearButton';
import ImageTitle from '@/components/ImageTitle';
import Gallery from '@/components/Gallery';
export default {
    name:'Text Over Fullscreen Image',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{
        AttachmentUI,
        Gallery,
        LinearButton,
        ImageTitle
    },
    data() {
        return {
            galleryVisible:false,
            videoVisible:false,              
            copy:this.page.copy  
        }
    },
    computed: {                
        gallery() {
            let images = [];        
            for (let index = 0; index < this.page?.gallery?.length; index++) {
                const element = this.page?.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        }
    }
}
</script>
<style lang="scss" scoped>
    @media (max-width:699px) {  
        .linear-holder {
            position: relative;
            height:90px;
        }
    }
    .template {
        display:grid;
        align-items: center;   
        position: relative;            
        grid-template-rows: 50vh 1fr;    
        @media (min-width:700px) {
            grid-template-rows: unset;
            grid-template-columns: 55px 40vw 1fr;                        
        }        
    }    
    .left-bar {
        background: linear-gradient(180deg, #F3F2F2 0%, #CACACA 100%);
        height: 100%;
        position: relative;        
        @media (max-width:699px) {
            display:none;
        }
    }                      
    .text-content {
        background: linear-gradient(134.76deg, #202020 0%, #121212 100%);                
        -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
        color-adjust: exact;     
        box-sizing: border-box;
        color: #F5F5F5;
        display: grid;        
        height:100%;
        position: relative;
        @media (min-width:700px) {
            place-items: center;
        }          
        > div:not(.linear-holder) {
            padding:13px;
            > div {
                padding:25px 0;
            }
            @media (min-width:700px) {
                padding:0 75px;
            }
        }
    }
    h1 {
        color: #B6B6B6;
        text-transform: uppercase;
        padding-bottom:20px;
        border-bottom:1px solid #0066CC;
        max-width: 540px;
    }
    .copy {
        border-bottom:1px solid #0066CC;
        padding:20px 0;
        max-width: 540px;
        @media (min-width:700px) {
            max-height: calc(100vh - 325px);
            overflow: auto;
        }
    }
    .images {                
        display:grid;          
        height:100%;                
        width:100%;   
        @media (min-width:700px) {
            position: absolute;
            top:0;
            left:0;
        }                
        > div {
            background-size: cover;
            background-position: center;
            -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
            color-adjust: exact;     
            overflow: hidden;
            display: flex;
            flex-direction: column;    
            position: relative;        
        }        
        .title {
            padding-top:100px;
        }
    }
</style>
